import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormSection from '../../FormSection';
import { FormMode } from '../../../../utils/Enums';
import FormDivider from '../../FormDivider';
import { ReactHookFormCheckbox, ReactHookFormCurrency } from '../../../ReactHookForm';
import { Alert } from '@mui/material';
import { UserProfileInputType } from '../../../../apollo/generated/types/globalTypes';

const AvailabilityForm: React.FC<AvailabilityFormProps> = ({ mode }) => {
	const {
		register,
		getValues,
		control,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext<UserProfileInputType>();
	const readOnly = mode === FormMode.View;

	// handle isfreelancer popup
	const [isFreelancer, setIsFreelancer] = useState(watch('independentFreelancer') ?? false);
	const [confirmFreelancerModalOpen, setConfirmFreelancerModalOpen] = useState<boolean>(false);

	useEffect(() => {
		if (!isFreelancer) {
			setValue('hourlyRate', null);
		}
	}, [isFreelancer]);

	const handleFreelanceChange = (e) => {
		if (e.target.checked) {
			setConfirmFreelancerModalOpen(true);
			return;
		}
		setValue('independentFreelancer', false);
		setIsFreelancer(false);
	};

	const closeConfirmFreelancerModal = () => setConfirmFreelancerModalOpen(false);

	const handleFreelancerModalOkClick = () => {
		setValue('independentFreelancer', true);
		setValue('notAvailable', false);
		setIsFreelancer(true);
		closeConfirmFreelancerModal();
	};

	// make sure hours per week is a valid number
	const validateHoursPerWeek = (e) => {
		let hours = parseInt(e.target.value);
		if (!isNaN(hours)) {
			if (hours < 0) hours = 0;
			if (hours > 168) hours = 168;
		}
		setValue(e.target.name, hours);
	};

	// set hours per week to 0 if 'not available' is marked
	const isNotAvailable = watch('notAvailable') ?? undefined;
	useEffect(() => {
		if (isNotAvailable) {
			setValue('availableHoursPerWeek', 0);
			setValue('independentFreelancer', false);
			setIsFreelancer(false);
		}
	}, [isNotAvailable]);

	return (
		<>
			<FormSection>
				<Grid item xs={12}>
					<Typography variant="h1">Availability</Typography>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="info">
						This is where you indicate how much time you have available beyond your current workload for additional work for either your group or as
						a freelancer for other groups. These Fields can be left blank and negotiated on a case by case basis.
					</Alert>
				</Grid>
			</FormSection>
			<FormDivider />
			<FormSection>
				<Grid item xs={6}>
					<ReactHookFormCheckbox
						name="notAvailable"
						label="Not Available"
						control={control}
						readOnly={readOnly}
						error={!!errors.notAvailable}
						helperText={errors.notAvailable?.message}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						variant="standard"
						name="availableHoursPerWeek"
						label={'Available Hours Per Week'}
						type="number"
						inputProps={{
							...register('availableHoursPerWeek', {
								valueAsNumber: true,
								min: {
									value: 0,
									message: 'Available Hours Per Week cannot be negative.',
								},
								max: {
									value: 168,
									message: 'Available Hours Per Week cannot exceed the number of hours in a week.',
								},
							}),
							min: 0,
							max: 168,
							step: 1,
						}}
						InputProps={{
							readOnly: readOnly || isNotAvailable,
						}}
						InputLabelProps={{ shrink: true }}
						fullWidth
						defaultValue={getValues('availableHoursPerWeek')}
						error={!!errors.availableHoursPerWeek}
						helperText={errors.availableHoursPerWeek?.message}
						onBlur={validateHoursPerWeek}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormControlLabel
						control={<Checkbox checked={isFreelancer} onChange={(e) => handleFreelanceChange(e)} color="primary" name="independentFreelancer" />}
						label="I am willing and allowed to collaborate"
						disabled={readOnly}
					/>
				</Grid>
				<Grid item xs={6}>
					<ReactHookFormCurrency
						name="hourlyRate"
						label="Hourly Rate"
						control={control}
						rules={{
							min: {
								value: 0,
								message: 'Hourly Rate cannot be negative.',
							},
						}}
						error={!!errors.hourlyRate}
						helperText={errors.hourlyRate?.message as string}
						allowDecimals={true}
						readOnly={readOnly || !isFreelancer}
					/>
				</Grid>

				<Dialog open={confirmFreelancerModalOpen} onClose={closeConfirmFreelancerModal}>
					<DialogTitle>Are You Sure?</DialogTitle>
					<DialogContent>I understand that I should not be taking on any work that is in competition with my employer.</DialogContent>
					<DialogActions>
						<Button onClick={closeConfirmFreelancerModal}>Cancel</Button>
						<Button onClick={handleFreelancerModalOkClick} color="primary" variant="contained">
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</FormSection>
		</>
	);
};

export interface AvailabilityFormProps {
	mode: FormMode;
}

export default AvailabilityForm;
